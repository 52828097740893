import React, { useState } from "react";
import s from "./Form.module.scss";
const defaultState = {
    name: "",
    email: "",
    phone: "",
    breweryName: "",
    website: "",
    currentHopSupplies: "",
    billingAddress: "",
    shippingAddress: "",
};
const Form = ({ formName }) => {
    const [state, setState] = useState(defaultState);
    const handleSetValue = (event) => {
        const { currentTarget: { name, value }, } = event;
        setState(state => ({ ...state, [name]: value }));
    };
    return (<section className={s.box}>
      <div>
        <b>Want to order this hop?</b>
        <p>
          Fill out the form below and we will reach out to you as soon as we can
        </p>
      </div>
      <form action={"/thank-you"} className={s.form} name={formName} method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value={formName}/>
        <div className={s.row}>
          <div className={s.col}>
            <input required className={s.input} id="name" name="name" placeholder="Name" value={state.name} onChange={handleSetValue}/>
          </div>
          <div className={s.col}>
            <input required className={s.input} id="breweryName" name="breweryName" placeholder="Brewery Name" value={state.breweryName} onChange={handleSetValue}/>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.col}>
            <input required className={s.input} id="email" name="email" placeholder="Email" value={state.email} type="email" onChange={handleSetValue}/>
          </div>
          <div className={s.col}>
            <input required className={s.input} id="phone" name="phone" placeholder="Phone" value={state.phone} onChange={handleSetValue}/>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.col}>
            <input required className={s.input} id="website" name="website" placeholder="Website" value={state.website} onChange={handleSetValue}/>
          </div>
          <div className={s.col}>
            <input required className={s.input} id="currentHopSupplies" name="currentHopSupplies" placeholder="Current Hop Supplier" value={state.currentHopSupplies} onChange={handleSetValue}/>
          </div>
        </div>
        <input required className={s.input} id="billingAddress" name="billingAddress" placeholder="Billing Address" value={state.billingAddress} onChange={handleSetValue}/>
        <input required className={s.input} id="shippingAddress" name="shippingAddress" placeholder="Shipping Address" value={state.shippingAddress} onChange={handleSetValue}/>
        <div className={s.btnBox}>
          <button className={s.btn} type="submit">
            Send
          </button>
        </div>
      </form>
    </section>);
};
export default Form;
