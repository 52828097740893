import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const { reservePdfImage, certifiedOrganic } = useStaticQuery(graphql `
    {
      reservePdfImage: file(
        relativePath: { eq: "images/hop_varieties_template/doc-file.svg" }
      ) {
        publicURL
      }
      certifiedOrganic: file(
        relativePath: { eq: "images/certificates/certified.png" }
      ) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
    return { reservePdfImage, certifiedOrganic };
};
