import React from "react";
import { Document, Page } from "react-pdf";
import s from "./Presentation.module.scss";
import useGetImage from "./useGetImage";
import Image from "gatsby-image";
const renderImage = url => {
    return url.indexOf(".pdf") >= 0 ? (<Document file={url} className={s.pdfImg}>
      <Page pageNumber={1} renderMode="svg"/>
    </Document>) : (<img src={url} alt="presentation"/>);
};
const Presentation = ({ pdfUrl, content }) => {
    const { reservePdfImage, certifiedOrganic } = useGetImage();
    return (<section className={s.box}>
      <div className={s.pdfBox}>
        {pdfUrl && pdfUrl.file.url ? (renderImage(pdfUrl.file.url)) : (<img src={reservePdfImage} alt="pdf-image"/>)}
      </div>
      <div className={s.infoBox}>
        <div>
          <p>
            <b>Alpha (%)</b>
          </p>
          <p>{content.alphaAcidRange}</p>
        </div>
        <div>
          <p>
            <b>Beta (%)</b>
          </p>
          <p>{content.betaAcidRange}</p>
        </div>
        <div>
          <p>
            <b>Total Oil (mL/100g)</b>
          </p>
          <p>{content.totalOilRange}</p>
        </div>
        <div>
          <p>
            <b>Aroma</b>
          </p>
          <p>{content.aromaProfile}</p>
        </div>
        <div>
          {content.organic && (<>
              <p>
                <b>This hop is certified organic</b>
              </p>
              <Image fluid={certifiedOrganic.childImageSharp.fluid} alt="Certified Organic" className={s.organicImage}/>
            </>)}
        </div>
        <div>
          {content.amarillo && (<button className={s.refineButton}>
              <a href="https://hoptechnic.com/app" target="_blank" rel="noopener noreferrer">
                Refine Search
              </a>
            </button>)}
        </div>
      </div>
    </section>);
};
export default Presentation;
