import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const { hero } = useStaticQuery(graphql `
    query {
      hero: file(
        relativePath: { eq: "images/hop_varieties_template/HopVariety_Desktop.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1900, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
    return hero;
};
