import React from "react";
import Hero from "../../components/hero";
import SectionSeparator from "../../components/sectionSeparator";
import Description from "./components/description";
import Presentation from "./components/presentation";
import useGetImage from "./useGetImage";
import Form from "./components/form";
import s from "./HopVarietiesTemplate.module.scss";
export default function HopVarietiesTemplate({ pageContext }) {
    const hero = useGetImage();
    const { url, alphaAcidRange, name, aromaProfile, betaAcidRange, totalOilRange, hopPdf, amarillo, organic, childContentfulHopVarietiesDescriptionRichTextNode, } = pageContext.data;
    const content = {
        alphaAcidRange,
        aromaProfile,
        betaAcidRange,
        totalOilRange,
        amarillo,
        organic,
    };
    return (<>
      <Hero imgURL={hero.childImageSharp.fluid}/>
      <SectionSeparator />
      <div className={s.wrap}>
        <div className={s.box}>
          <h1>{name}</h1>
        </div>
        {childContentfulHopVarietiesDescriptionRichTextNode ? (<Description>
            {childContentfulHopVarietiesDescriptionRichTextNode}
          </Description>) : null}
        <Presentation pdfUrl={hopPdf} content={content}/>
        <Form formName={url}/>
      </div>
    </>);
}
